<template>
  <div
    class="app-content  bg-custome-height pb-0"
    :class="[
      { 'show-overlay': $store.state.app.shallShowOverlay },
      $route.meta.contentClass,
      $store.state.app.boardBackGroundColor
        ? $store.state.app.boardBackGroundColor
        : null,
    ]"
  >
    <div class="content-overlay" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition :name="routerTransition" mode="out-in">
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import AppBreadcrumb from "@core/layouts/components/AppBreadcrumb.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { eventBus } from "@/main";
export default {
  components: {
    AppBreadcrumb,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();

    return {
      routerTransition,
      contentWidth,
    };
  },
  data() {
    return {
      backgroundColor: null,
    };
  },
  mounted() {
    eventBus.$on("backgroundColorSet", (data) => {
      if (data) {
        this.$store.state.app.boardBackGroundColor = data;
      }
    });
    eventBus.$on("backgroundColor", (data) => {
      if (data) {
        this.$store.state.app.boardBackGroundColor = null;
      }
    });
  },
  computed: {
    currentURL() {
      return window.location.href;
    },
  },
  destroyed() {
    eventBus.$off();
    this.backgroundColor = null;
  },
};
</script>
